import renovateForumPageServerProps from '@oneflare-server-side-props/_renovateForumPage';
import RenovateForum from 'pages/oneflare.com.au/RenovateForum/RenovateForum';
import { RenovateForumPageProps } from 'types/oneflare.com.au/renovateForum';

const RenovateForumPage = (props: RenovateForumPageProps) => (
  <RenovateForum {...props} />
);

const getServerSideProps = renovateForumPageServerProps;

export { RenovateForumPage as default, getServerSideProps };

import Button from '@oneflare/flarekit/lib/components/Button';
import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';
import H2 from '@oneflare/flarekit/lib/components/H2';
import media from '@oneflare/flarekit/lib/libs/media';
import Image from 'next/image';
import { styled } from 'styled-components';

const WrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.color.tin};
`;

const ContainerStyled = styled.div`
  max-width: 1135px;
  padding: 64px 16px 80px;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.charcoal};
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;

  ${media.mdUp`
    padding: 64px 32px;
    flex-direction: row;
    column-gap: 40px;
    align-items: start;
    justify-content: space-between;
  `}

  ${media.lgUp`
    padding: 72px 0;
  `}
`;

const TitleStyled = styled(H2)`
  font-size: 28px;
  margin-bottom: 32px;

  ${media.lgUp`
    font-size: 40px;
  `}
`;

const ImageStyled = styled(Image)`
  max-width: 100%;

  ${media.mdUp`
    flex: 0 0 40%;
    width: 100%;
    height: 100%;
  `}
`;

const TextStyled = styled.p<{ $bold?: boolean }>`
  margin: 16px 0;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.gunMetal};
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'regular')};
`;

const ButtonWrapperStyled = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 24px;

  ${media.lgUp`
    flex-direction: row;
  `}
`;

const AirtaskerButtonStyled = styled(ButtonLink)`
  width: 100%;
`;

const PostJobButtonStyled = styled(Button)`
  width: 100%;
`;

const CategoryContainerStyled = styled.div`
  margin: 64px auto;
`;

export {
  AirtaskerButtonStyled,
  ButtonWrapperStyled,
  CategoryContainerStyled,
  ContainerStyled,
  ImageStyled,
  PostJobButtonStyled,
  TextStyled,
  TitleStyled,
  WrapperStyled
};

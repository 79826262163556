import { useCallback, useContext } from 'react';
import {
  AirtaskerButtonStyled,
  ButtonWrapperStyled,
  CategoryContainerStyled,
  ContainerStyled,
  ImageStyled,
  PostJobButtonStyled,
  TextStyled,
  TitleStyled,
  WrapperStyled
} from './styled/RenovateForumStyled';

import { publicRuntimeConfig } from 'lib/utils/Environment';
import dynamic from 'next/dynamic';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import PageHeader from 'shared/components/PageHeader/PageHeader';
import HeadContent from 'shared/components/_oneflare/HeadContent/HeadContent';
import { RenovateForumPageProps } from 'types/oneflare.com.au/renovateForum';

const PAGE_TITLE =
  'Renovate Forum.com shut its doors on May 22nd 2023 - Oneflare';

const PopularCategories = dynamic(
  () => import('shared/components/PopularCategories/PopularCategories')
);

const RenovateForumPage = ({
  params: { staticRender },
  popularCategoriesData
}: RenovateForumPageProps) => {
  const { controller } = useContext(JobFormControllerContext);

  const handleJobFormLaunch = useCallback(() => {
    controller?.open();
  }, [controller]);

  return (
    <>
      <HeadContent
        pageUrl={publicRuntimeConfig.SITE_URL}
        pageTitle={PAGE_TITLE}
      />
      <PageHeader />
      <WrapperStyled>
        <ContainerStyled>
          <div>
            <TitleStyled>
              Renovate Forum.com shut its doors on May 22nd 2023.
            </TitleStyled>
            <TextStyled $bold>
              Renovate Forum was established in 1999 and we've enjoyed watching
              it grow to become Australia's largest online renovation forum with
              more than 40,000 members. We've seen thousands of avid home
              renovators search for timely, practical and expert advice on their
              latest building or DIY projects, always ready to be welcomed by
              the community's army of trusted trade professionals eager to share
              their expertise and industry tips.
            </TextStyled>
            <TextStyled>
              But we are excited to continue supporting your renovation
              adventures through connecting you to trusted trade professionals
              on Oneflare and Airtasker community to help get your job done and
              help your renovation visions come to life.
            </TextStyled>
            <ButtonWrapperStyled>
              <PostJobButtonStyled
                label="Get a Quote on Oneflare"
                onClick={handleJobFormLaunch}
              />
              <AirtaskerButtonStyled
                kind="primary"
                link="https://www.airtasker.com/post-task/?origin=oneflare"
              >
                Post a task on Airtasker
              </AirtaskerButtonStyled>
            </ButtonWrapperStyled>
          </div>
          <ImageStyled
            src="https://cdn.oneflare.com/static/client/directory/renovate-forum-tools.svg"
            alt="Renovate Forum tools"
            width={454}
            height={360}
          />
        </ContainerStyled>
      </WrapperStyled>
      <CategoryContainerStyled>
        <PopularCategories
          isBot={staticRender}
          popularCategoriesData={popularCategoriesData}
        />
      </CategoryContainerStyled>
    </>
  );
};

export default RenovateForumPage;
